import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IUserProfileCreate, IUserProfileUpdate } from '@/interfaces';
import { State } from '../state';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetUsers, commitSetUser } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

import { IMerchantCreate } from '@/interfaces';

import { ICategoryCreate } from '@/interfaces';
import { commitSetCategories } from './mutations';
// import { commitSetMerchant } from './mutations';
import { commitSetBrands } from './mutations';
import { commitSetBrandExts } from './mutations';

import { commitSetTOS } from './mutations';

import { ttt } from '@/nutils';


type MainContext = ActionContext<AdminState, State>;

export const actions = {
    async actionGetUsers(context: MainContext) {
        try {
            const response = await api.getUsers(context.rootState.main.token);
            if (response) {
                commitSetUsers(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateUser(context: MainContext, payload: { id: number, user: IUserProfileUpdate }) {
        try {
            const loadingNotification = { content: ttt('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateUser(context.rootState.main.token, payload.id, payload.user),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: ttt('User successfully updated'), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
        try {
            const loadingNotification = { content: ttt('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createUser(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: ttt('User successfully created'), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionCreateMerchant(context: MainContext, payload: IMerchantCreate) {
        const loadingNotification = { content: ttt('saving'), showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createMerchant(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            // commitSetMerchant(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: ttt('Merchant successfully created'), color: 'success' });
            return true;
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            const errMsg = `${ttt('Merchant not created')} : ${error.response.data.detail}`
            commitAddNotification(context, { color: 'error', content: errMsg });
            await dispatchCheckApiError(context, error);
            return false;
        }
    },

    async actionGetCategories(context: MainContext) {
        try {
            const response = await api.getCategories(context.rootState.main.token);
            if (response) {
                commitSetCategories(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateCategory(context: MainContext, payload: ICategoryCreate) {
        try {
            const loadingNotification = { content: ttt('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createCategory(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: ttt('Category successfully created'), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionBulkUpdateCategories(context: MainContext, payload: string[]) {
        try {
            const loadingNotification = { content: ttt('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.bulkupdateCategories(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: ttt('Categories successfully updated'), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionGetBrands(context: MainContext) {
        try {
            const response = await api.getBrands(context.rootState.main.token);
            if (response) {
                commitSetBrands(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionGetBrandExts(context: MainContext) {
        try {
            const response = await api.getBrandExts(context.rootState.main.token);
            if (response) {
                commitSetBrandExts(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionRunUpdateBrandProducts(context: MainContext, ids: string) {
        try {
            const loadingNotification = { content: ttt('running'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.runUpdateBrandProducts(context.rootState.main.token, ids),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: ttt('Update Products Launched'), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionRunUpdateBrandWidget(context: MainContext, ids: string) {
        try {
            const loadingNotification = { content: ttt('running'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.runUpdateBrandWidget(context.rootState.main.token, ids),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: ttt('Update Widgets Launched'), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionRunUpdateBrandPartners(context: MainContext, ids: string) {
        try {
            const loadingNotification = { content: ttt('running'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.runUpdateBrandPartners(context.rootState.main.token, ids),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: ttt('Update Partners Launched'), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionRunPublishBrandWidget(context: MainContext, ids: string) {
        try {
            const loadingNotification = { content: ttt('running'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.runPublishBrandWidget(context.rootState.main.token, ids),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: ttt('Publish Widgets Launched'), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionRunUpdateBrandStatistics(context: MainContext, ids: string) {
        try {
            const loadingNotification = { content: ttt('running'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.runUpdateBrandStatistics(context.rootState.main.token, ids),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: ttt('Update Statistics Launched'), color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionGetTOS(context: MainContext) {
        try {
            const response = await api.getTOS(context.rootState.main.token);
            if (response.data) {
                commitSetTOS(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);

export const dispatchCreateMerchant = dispatch(actions.actionCreateMerchant);

export const dispatchGetCategories = dispatch(actions.actionGetCategories);
export const dispatchCreateCategory = dispatch(actions.actionCreateCategory);
export const dispatchBulkUpdateCategories = dispatch(actions.actionBulkUpdateCategories);

export const dispatchGetBrands = dispatch(actions.actionGetBrands);
export const dispatchGetBrandExts = dispatch(actions.actionGetBrandExts);

export const dispatchRunUpdateBrandProducts = dispatch(actions.actionRunUpdateBrandProducts);
export const dispatchRunUpdateBrandWidget = dispatch(actions.actionRunUpdateBrandWidget);
export const dispatchRunUpdateBrandPartners = dispatch(actions.actionRunUpdateBrandPartners);
export const dispatchRunPublishBrandWidget = dispatch(actions.actionRunPublishBrandWidget);
export const dispatchRunUpdateBrandStatistics = dispatch(actions.actionRunUpdateBrandStatistics);

export const dispatchGetTOS = dispatch(actions.actionGetTOS);
