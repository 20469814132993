import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IBrandCreate, IBrandUpdate } from '@/interfaces';
import { State } from '../state';
import { AffiState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetBrands, commitSetBrand } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

import { IMerchantCreate } from '@/interfaces';
// import { commitSetMerchant } from './mutations';
import { commitSetCategories } from './mutations';
import { IProductUpdate, IProductImageUpdate } from '@/interfaces';
import { commitSetProducts, commitSetProduct } from './mutations';
import { IPartnerUpdate } from '@/interfaces';
import { commitSetPartners, commitSetPartner } from './mutations';
import { commitSetWidgets } from './mutations';
import { commitSetKPIBrands } from './mutations';
import { commitSetEmbedCodes } from './mutations';


type MainContext = ActionContext<AffiState, State>;

export const actions = {
    async actionGetBrands(context: MainContext) {
        try {
            const response = await api.getBrands(context.rootState.main.token);
            if (response) {
                commitSetBrands(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateBrand(context: MainContext, payload: { id: number, brand: IBrandUpdate }) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateBrand(context.rootState.main.token, payload.id, payload.brand),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetBrand(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Brand successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCreateBrand(context: MainContext, payload: IBrandCreate) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createBrand(context.rootState.main.token, payload),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetBrand(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Brand successfully created', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionGetCategories(context: MainContext) {
        try {
            const response = await api.getCategories(context.rootState.main.token);
            if (response) {
                commitSetCategories(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionGetProducts(context: MainContext, filterOwnerId: number = -1) {
        try {
            const response = await api.getProducts(context.rootState.main.token);
            if (response) {
                const products = response.data
                    .filter( (obj) => ( filterOwnerId === -1 || obj && obj.owner_id === filterOwnerId ) );
                products.sort((e1,e2) => e1.position_order - e2.position_order);
                commitSetProducts(context, products);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateProduct(context: MainContext, payload: { id: number, product: IProductUpdate }) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateProduct(context.rootState.main.token, payload.id, payload.product),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetProduct(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Product successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateProductImage(context: MainContext, payload: { id: number, product: IProductImageUpdate }) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateProductImage(context.rootState.main.token, payload.id, payload.product),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetProduct(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Product image successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateProductBulk(context: MainContext, payload: { id: number, product: IProductUpdate }[]) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            for (const e of payload) {
                // console.log(`write ${e.id} - ${e.product}`);
                const response = (await Promise.all([
                    api.updateProduct(context.rootState.main.token, e.id, e.product),
                    await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
                ]))[0];
                // avoid firing reactive refresh on each loop cycle.
                // might left store dirty.
                // commitSetProduct(context, response.data);
            };
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Products successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionGetPartners(context: MainContext, filterOwnerId: number = -1) {
        try {
            const response = await api.getPartners(context.rootState.main.token);
            if (response) {
                const partners = response.data
                    .filter( (obj) => ( filterOwnerId === -1 || obj && obj.owner_id === filterOwnerId ) );
                partners.sort((e1,e2) => e1.position_order - e2.position_order);
                commitSetPartners(context, partners);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdatePartner(context: MainContext, payload: { id: number, partner: IPartnerUpdate }) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updatePartner(context.rootState.main.token, payload.id, payload.partner),
                await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPartner(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Partner successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdatePartnerBulk(context: MainContext, payload: { id: number, partner: IPartnerUpdate }[]) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            for (const e of payload) {
                const response = (await Promise.all([
                    api.updatePartner(context.rootState.main.token, e.id, e.partner),
                    await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
                ]))[0];
                // avoid firing reactive refresh on each loop cycle.
                // might left store dirty.
                // commitSetPartner(context, response.data);
            };
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Partners successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionGetWidgets(context: MainContext) {
        try {
            const response = await api.getWidgets(context.rootState.main.token);
            if (response) {
                commitSetWidgets(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionInstallShopify(context: MainContext, payload: { shopUrl: string, callback: (data: any) => any }) {
        try {
            const response = await api.installShopify(context.rootState.main.token, payload.shopUrl);
            payload.callback(response.data);
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionAskPayment(context: MainContext, payload: { callback: (data: any) => any }) {
        try {
            const response = await api.askPayment(context.rootState.main.token);
            payload.callback(response.data);
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionAskPaymentEmail(context: MainContext, payload: { email: string, callback: (data: any) => any }) {
        try {
            const response = await api.askPaymentEmail(context.rootState.main.token, payload.email);
            payload.callback(response.data);
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionGetKPIBrands(context: MainContext) {
        try {
            const response = await api.getKPIBrands(context.rootState.main.token);
            if (response) {
                commitSetKPIBrands(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },

    async actionGetEmbedCodes(context: MainContext) {
        try {
            const response = await api.getEmbedCodes(context.rootState.main.token);
            if (response) {
                commitSetEmbedCodes(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<AffiState, State>('');

export const dispatchCreateBrand = dispatch(actions.actionCreateBrand);
export const dispatchGetBrands = dispatch(actions.actionGetBrands);
export const dispatchUpdateBrand = dispatch(actions.actionUpdateBrand);

export const dispatchGetCategories = dispatch(actions.actionGetCategories);

export const dispatchGetProducts = dispatch(actions.actionGetProducts);
export const dispatchUpdateProduct = dispatch(actions.actionUpdateProduct);
export const dispatchUpdateProductImage = dispatch(actions.actionUpdateProductImage);
export const dispatchUpdateProductBulk = dispatch(actions.actionUpdateProductBulk);

export const dispatchGetPartners = dispatch(actions.actionGetPartners);
export const dispatchUpdatePartner = dispatch(actions.actionUpdatePartner);
export const dispatchUpdatePartnerBulk = dispatch(actions.actionUpdatePartnerBulk);

export const dispatchGetWidgets = dispatch(actions.actionGetWidgets);

export const dispatchInstallShopify = dispatch(actions.actionInstallShopify);
export const dispatchAskPayment = dispatch(actions.actionAskPayment);
export const dispatchAskPaymentEmail = dispatch(actions.actionAskPaymentEmail);

export const dispatchGetKPIBrands = dispatch(actions.actionGetKPIBrands);

export const dispatchGetEmbedCodes = dispatch(actions.actionGetEmbedCodes);
