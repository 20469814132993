import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'createmerchantopen',
          component: () => import(/* webpackChunkName: "createmerchantopen" */ './views/CreateMerchantOpen.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'),
            },
            {
              path: 'brandsettings',
              component: () => import(/* webpackChunkName: "main-brandsettings" */ './views/main/affi/BrandSettings.vue'),
            },
            {
              path: 'products',
              component: () => import(/* webpackChunkName: "main-products" */ './views/main/affi/Products.vue'),
            },
            {
              path: 'partners',
              component: () => import(/* webpackChunkName: "main-partners" */ './views/main/affi/Partners.vue'),
            },
            {
              path: 'preview',
              component: () => import(/* webpackChunkName: "main-preview" */ './views/main/affi/Preview.vue'),
            },
            {
              path: 'embedcodes',
              component: () => import(/* webpackChunkName: "main-embedcodes" */ './views/main/affi/EmbedCodes.vue'),
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                    /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'),
                },
                {
                  path: 'categories',
                  name: 'main-admin-categories',
                  component: () => import(
                    /* webpackChunkName: "main-admin-categories" */ './views/main/admin/AdminCategories.vue'),
                },
                {
                  path: 'selectmerchant',
                  name: 'main-admin-selectmerchant',
                  component: () => import(
                    /* webpackChunkName: "main-admin-selectmerchant" */ './views/main/admin/AdminSelectMerchant.vue'),
                },
                {
                  path: 'services',
                  name: 'main-admin-services',
                  component: () => import(
                    /* webpackChunkName: "main-admin-services" */ './views/main/admin/Services.vue'),
                },
                {
                  path: 'admindashboard',
                  name: 'main-admin-dashboard',
                  component: () => import(
                    /* webpackChunkName: "main-admin-dashboard" */ './views/main/admin/AdminDashboard.vue'),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
