import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate } from './interfaces';
import { IMerchantCreate } from './interfaces';
import { IBrand, IBrandCreate, IBrandUpdate } from './interfaces';
import { IKPIBrand } from './interfaces';
import { ICategory, ICategoryCreate } from './interfaces';
import { IProduct, IProductUpdate, IProductImageUpdate } from './interfaces';
import { IPartnerBrand, IPartnerUpdate } from './interfaces';
import { IWidget } from './interfaces';
import { IBrandExt } from './interfaces';
import { IEmbedCode } from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },

  async createMerchant(token: string, data: IMerchantCreate) {
    return axios.post(`${apiUrl}/api/v1/merchants/open`, data, authHeaders(token));
  },
  async getBrands(token: string) {
    return axios.get<IBrand[]>(`${apiUrl}/api/v1/brands/`, authHeaders(token));
  },
  async createBrand(token: string, data: IBrandCreate) {
    return axios.post(`${apiUrl}/api/v1/brands/`, data, authHeaders(token));
  },
  async updateBrand(token: string, userId: number, data: IBrandUpdate) {
    return axios.put(`${apiUrl}/api/v1/brands/${userId}`, data, authHeaders(token));
  },
  async getBrandMine(token: string) {
    return axios.get<IBrand>(`${apiUrl}/api/v1/brands/mine/`, authHeaders(token));
  },

  async getMyBrand(token: string) {
    return axios.get<IBrand>(`${apiUrl}/api/v1/brands/mine/`, authHeaders(token));
  },
  async getMyKPIBrand(token: string) {
    return axios.get<IKPIBrand>(`${apiUrl}/api/v1/kpibrands/mine/`, authHeaders(token));
  },
  async getKPIBrands(token: string) {
    return axios.get<IKPIBrand[]>(`${apiUrl}/api/v1/kpibrands/`, authHeaders(token));
  },

  async getCategories(token: string) {
    return axios.get<ICategory[]>(`${apiUrl}/api/v1/categories/`, authHeaders(token));
  },
  async createCategory(token: string, data: ICategoryCreate) {
    return axios.post(`${apiUrl}/api/v1/categories/`, data, authHeaders(token));
  },
  async bulkupdateCategories(token: string, data: string[]) {
    return axios.post(`${apiUrl}/api/v1/categories/bulk-update`, data, authHeaders(token));
  },

  async getNewest3(token: string) {
    return axios.get<IBrand[]>(`${apiUrl}/api/v1/brands/newest3/`, authHeaders(token));
  },

  async getProducts(token: string) {
    return axios.get<IProduct[]>(`${apiUrl}/api/v1/products/`, authHeaders(token));
  },
  async updateProduct(token: string, userId: number, data: IProductUpdate) {
    return axios.put(`${apiUrl}/api/v1/products/${userId}`, data, authHeaders(token));
  },
  async updateProductImage(token: string, userId: number, data: IProductImageUpdate) {
    return axios.put(`${apiUrl}/api/v1/products/${userId}`, data, authHeaders(token));
  },

  async getPartners(token: string) {
    return axios.get<IPartnerBrand[]>(`${apiUrl}/api/v1/partners/`, authHeaders(token));
  },
  async updatePartner(token: string, userId: number, data: IPartnerUpdate) {
    return axios.put(`${apiUrl}/api/v1/partners/${userId}`, data, authHeaders(token));
  },

  async runUpdateBrandProducts(token: string, ids: string) {
    const data = { msg: ids };
    return axios.post(`${apiUrl}/api/v1/procs/update-brand-products/`, data, authHeaders(token));
  },
  async runUpdateBrandWidget(token: string, ids: string) {
    const data = { msg: ids };
    return axios.post(`${apiUrl}/api/v1/procs/update-brand-widget/`, data, authHeaders(token));
  },
  async runUpdateBrandPartners(token: string, ids: string) {
    const data = { msg: ids };
    return axios.post(`${apiUrl}/api/v1/procs/update-brand-partners/`, data, authHeaders(token));
  },
  async runPublishBrandWidget(token: string, ids: string) {
    const data = { msg: ids };
    return axios.post(`${apiUrl}/api/v1/procs/publish-brand-widget/`, data, authHeaders(token));
  },
  async runUpdateBrandStatistics(token: string, ids: string) {
    const data = { msg: ids };
    return axios.post(`${apiUrl}/api/v1/procs/update-brand-statistics/`, data, authHeaders(token));
  },
  async getWidgets(token: string) {
    return axios.get<IWidget[]>(`${apiUrl}/api/v1/widgets/`, authHeaders(token));
  },

  async installShopify(token: string, shopUrl: string) {
    return axios.get(`${apiUrl}/api/v1/shop_connect/shopify_install/${shopUrl}`, authHeaders(token));
  },
  async askPayment(token: string) {
    return axios.get(`${apiUrl}/api/v1/shop_connect/ask-payment`, authHeaders(token));
  },
  async askPaymentEmail(token: string, email: string) {
    return axios.get(`${apiUrl}/api/v1/shop_connect/ask-payment-email/${email}`, authHeaders(token));
  },
  async getBrandExts(token: string) {
    return axios.get<IBrandExt[]>(`${apiUrl}/api/v1/brandexts/`, authHeaders(token));
  },

  async getTOS(token: string) {
    return axios.get<string>(`${apiUrl}/api/v1/utils/get_tos/`);
  },

  async getPrivacyPolicy(token: string) {
    return axios.get<string>(`${apiUrl}/api/v1/utils/get_privacy_policy/`);
  },

  async uploadFile(token: string, path: string, payload: File) {
    const formData = new FormData();
    formData.append('file', payload);
    const headers = authHeaders(token);
    headers.headers['Content-Type'] = 'multipart/form-data';
    return axios.post(`${apiUrl}/api/v1/utils/uploadfile/`,
                      formData, { headers: headers.headers, params: { path } });
  },

  async getEmbedCodes(token: string) {
    return axios.get<IEmbedCode[]>(`${apiUrl}/api/v1/embedcodes/`, authHeaders(token));
  },
};
