import { AffiState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    affiBrands: (state: AffiState) => state.brands,
    affiOneBrand: (state: AffiState) => (brandId: number) => {
        const filteredBrands = state.brands.filter((brand) => brand.id === brandId);
        if (filteredBrands.length > 0) {
            return { ...filteredBrands[0] };
        }
    },

    affiCategories: (state: AffiState) => state.categories,

    affiProducts: (state: AffiState) => state.products,

    affiPartners: (state: AffiState) => state.partners,

    affiWidgets: (state: AffiState) => state.widgets,

    affiImpersonated: (state: AffiState) => state.impersonatedOwnerId,
    affiImpersonatedName: (state: AffiState) => state.impersonatedName,

    affiKPIBrands: (state: AffiState) => state.kpibrands,

    affiEmbedCodes: (state: AffiState) => state.embedcodes,
};

const { read } = getStoreAccessors<AffiState, State>('');

export const readAffiOneBrand = read(getters.affiOneBrand);
export const readAffiBrands = read(getters.affiBrands);

export const readCategories = read(getters.affiCategories);

export const readProducts = read(getters.affiProducts);

export const readPartners = read(getters.affiPartners);

export const readWidgets = read(getters.affiWidgets);

export const readImpersonated = read(getters.affiImpersonated);
export const readImpersonatedName = read(getters.affiImpersonatedName);

export const readKPIBrands = read(getters.affiKPIBrands);

export const readEmbedCodes = read(getters.affiEmbedCodes);
