import { IUserProfile } from '@/interfaces';
import { IMerchant } from '@/interfaces';
import { ICategory } from '@/interfaces';
import { IBrand } from '@/interfaces';
import { IBrandExt } from '@/interfaces';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setUsers(state: AdminState, payload: IUserProfile[]) {
        state.users = payload;
    },
    setUser(state: AdminState, payload: IUserProfile) {
        const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
        users.push(payload);
        state.users = users;
    },

    /*
    setMerchant(state: AdminState, payload: IMerchant) {
        const newuser: IUserProfile {
            id: payload.user_id,
            email: payload.email,
            first_name: payload.first_name,
            last_name: payload.last_name
        };
        const users = state.users.filter((user: IUserProfile) => user.id !== payload.user_id);
        users.push(newuser);
        state.users = users;
    },
   */

    setCategories(state: AdminState, payload: ICategory[]) {
        state.categories = payload;
    },
    setBrands(state: AdminState, payload: IBrand[]) {
        state.brands = payload;
    },
    setBrandExts(state: AdminState, payload: IBrandExt[]) {
        state.brandexts = payload;
    },

    setTOS(state: AdminState, payload: string) {
        state.tos = payload;
    },
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);

// export const commitSetMechant = commit(mutations.setMerchant);
export const commitSetCategories = commit(mutations.setCategories);
export const commitSetBrands = commit(mutations.setBrands);
export const commitSetBrandExts = commit(mutations.setBrandExts);

export const commitSetTOS = commit(mutations.setTOS);
