import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
         primary: '#8c52ff',
         secondary: '#D0B8FF',
         accent: '#8c52ff',
         error: '#FF52A2',
         info: '#D0B8FF',
         success: '#8c52ff',
         warning: '#FF52A2',
  }
});

/*
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
         primary: '#1976D2',
         secondary: '#D0B8FF',
         accent: '#1976D2',
         error: '#FF52A2',
         info: '#D0B8FF',
         success: '#1976D2',
         warning: '#FF52A2',
      },
    },
  },
})
*/
