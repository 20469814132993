import { IUserProfile } from '@/interfaces';
import { IBrand } from '@/interfaces';
import { IKPIBrand } from '@/interfaces';
import { MainState, AppNotification } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';


export const mutations = {
    setToken(state: MainState, payload: string) {
        state.token = payload;
    },
    setLoggedIn(state: MainState, payload: boolean) {
        state.isLoggedIn = payload;
    },
    setLogInError(state: MainState, payload: boolean) {
        state.logInError = payload;
    },
    setUserProfile(state: MainState, payload: IUserProfile) {
        state.userProfile = payload;
    },
    setDashboardMiniDrawer(state: MainState, payload: boolean) {
        state.dashboardMiniDrawer = payload;
    },
    setDashboardShowDrawer(state: MainState, payload: boolean) {
        state.dashboardShowDrawer = payload;
    },
    addNotification(state: MainState, payload: AppNotification) {
        state.notifications.push(payload);
    },
    removeNotification(state: MainState, payload: AppNotification) {
        state.notifications = state.notifications.filter((notification) => notification !== payload);
    },

    setUserBrand(state: MainState, payload: IBrand) {
        state.brand = payload;
    },
    setUserKPIBrand(state: MainState, payload: IKPIBrand) {
        state.kpibrand = payload;
    },
    setNewest3(state: MainState, payload: IBrand[]) {
        state.newest3 = payload;
    },

    setPrivacyPolicy(state: MainState, payload: string) {
        state.privacyPolicy = payload;
    },
};

const {commit} = getStoreAccessors<MainState | any, State>('');

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);

export const commitSetUserBrand = commit(mutations.setUserBrand);
export const commitSetUserKPIBrand = commit(mutations.setUserKPIBrand);
export const commitSetNewest3 = commit(mutations.setNewest3);

export const commitSetPrivacyPolicy = commit(mutations.setPrivacyPolicy);
