import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { AffiState } from './state';

const defaultState: AffiState = {
  brands: [],
  categories: [],
  impersonatedOwnerId: -1,
  impersonatedName: '',
  products: [],
  partners: [],
  widgets: [],
  kpibrands: [],
  embedcodes: [],
};

export const affiModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
