import { AffiState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

import { IBrand } from '@/interfaces';
import { ICategory } from '@/interfaces';
import { IProduct } from '@/interfaces';
import { IPartnerBrand } from '@/interfaces';
import { IWidget } from '@/interfaces';
import { IKPIBrand } from '@/interfaces';
import { IEmbedCode } from '@/interfaces';

export const mutations = {
    setBrands(state: AffiState, payload: IBrand[]) {
        state.brands = payload;
    },
    setBrand(state: AffiState, payload: IBrand) {
        const brands = state.brands.filter((brand: IBrand) => brand.id !== payload.id);
        brands.push(payload);
        state.brands = brands;
    },

    setCategories(state: AffiState, payload: ICategory[]) {
        state.categories = payload;
    },

    setImpersonatedOwnerId(state: AffiState, id: number) {
        state.impersonatedOwnerId = id;
    },
    setImpersonatedName(state: AffiState, name: string) {
        state.impersonatedName = name;
    },

    setProducts(state: AffiState, payload: IProduct[]) {
        state.products = payload;
    },
    setProduct(state: AffiState, payload: IProduct) {
        const products = state.products.filter((product: IProduct) => product.id !== payload.id);
        products.push(payload);
        state.products = products;
    },

    setPartners(state: AffiState, payload: IPartnerBrand[]) {
        state.partners = payload;
    },
    setPartner(state: AffiState, payload: IPartnerBrand) {
        const partners = state.partners.filter((product: IPartnerBrand) => product.id !== payload.id);
        partners.push(payload);
        state.partners = partners;
    },

    setWidgets(state: AffiState, payload: IWidget[]) {
        state.widgets = payload;
    },

    setKPIBrands(state: AffiState, payload: IKPIBrand[]) {
        state.kpibrands = payload;
    },

    setEmbedCodes(state: AffiState, payload: IEmbedCode[]) {
        state.embedcodes = payload;
    },
};

const { commit } = getStoreAccessors<AffiState, State>('');

export const commitSetBrand = commit(mutations.setBrand);
export const commitSetBrands = commit(mutations.setBrands);

export const commitSetCategories = commit(mutations.setCategories);

export const commitSetImpersonatedOwnerId = commit(mutations.setImpersonatedOwnerId);
export const commitSetImpersonatedName = commit(mutations.setImpersonatedName);

export const commitSetProducts = commit(mutations.setProducts);
export const commitSetProduct = commit(mutations.setProduct);

export const commitSetPartners = commit(mutations.setPartners);
export const commitSetPartner = commit(mutations.setPartner);

export const commitSetWidgets = commit(mutations.setWidgets);

export const commitSetKPIBrands = commit(mutations.setKPIBrands);

export const commitSetEmbedCodes = commit(mutations.setEmbedCodes);
